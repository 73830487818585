import { SystemComponentSearchResult } from '@priva/next-model';

import { EntityType, IdNameDescription } from 'app/shared';

import { SearchGroupType } from './search-group.enum';

export interface SearchResultGroup {
    searchResultGroupId: SearchGroupType;
    results: SearchResult[];
}

export interface SearchResult {
    entityType: EntityType;
    entity: IdNameDescription;
    entityProperties: EntityProperty[];
}

export interface EntityProperty {
    id: string;
    value: any;
    searchProperty: boolean;
}

export function mapToSearchResultGroup(deviceTemplateSearchResults: SystemComponentSearchResult[]) {
    const searchResults = deviceTemplateSearchResults.map((deviceTemplateSearchResult) => {
        return {
            entityType: EntityType.DeviceTemplate,
            entity: {
                id: deviceTemplateSearchResult.profileId,
                name: deviceTemplateSearchResult.profileName,
            },
            entityProperties: [
                { id: 'solutionId', value: deviceTemplateSearchResult.solutionId, searchProperty: false },
                { id: 'solutionName', value: deviceTemplateSearchResult.solutionName, searchProperty: true },
            ],
        };
    });
    const searchResultGroup = {
        searchResultGroupId: SearchGroupType.DeviceTemplates,
        results: searchResults,
    };
    return searchResultGroup;
}
