export enum SearchGroupType {
    DeviceTemplates = 'deviceTemplates',
    DeviceTemplateGroups = 'deviceTemplateGroups',
    Building = 'building',
}

export enum SearchTab {
    All = 'all',
    DeviceTemplates = 'deviceTemplates',
    DeviceTemplateGroups = 'deviceTemplateGroups',
    Building = 'building',
}
