import { Component, EventEmitter, Input, Output } from '@angular/core';

import { SearchTab } from 'app/search/search-group.enum';

@Component({
    selector: 'app-search-dialog-tabs',
    templateUrl: './search-dialog-tabs.component.html',
})
export class SearchDialogTabsComponent {
    @Input()
    public activeTab: SearchTab;

    @Output()
    public selectTab: EventEmitter<SearchTab> = new EventEmitter<SearchTab>();

    public SearchTab = SearchTab;
}
