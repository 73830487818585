import { Pipe, PipeTransform } from '@angular/core';

import { SearchGroupType } from '../search-group.enum';

@Pipe({
    name: 'searchGroupKey',
})
export class SearchGroupKeyPipe implements PipeTransform {
    public transform(searchGroupId: SearchGroupType): string {
        return `APP.SEARCH.GROUP_${searchGroupId}`.toUpperCase();
    }
}
