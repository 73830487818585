import { Pipe, PipeTransform } from '@angular/core';

import { BotZoneType } from '@priva/next-model';

import { SearchResult } from '../search-result.model';

@Pipe({
    name: 'zoneType',
})
export class SearchZoneTypePipe implements PipeTransform {
    public transform(searchResult: SearchResult): BotZoneType {
        return searchResult.entityProperties.find((e) => e.id === 'zoneType').value as BotZoneType;
    }
}
