import { Pipe, PipeTransform } from '@angular/core';

import { EntityType } from 'app/shared/entity';

import { SearchResult } from '../search-result.model';

@Pipe({
    name: 'searchSpecification',
})
export class SearchSpecificationPipe implements PipeTransform {
    public transform(searchResult: SearchResult): string {
        switch (searchResult.entityType) {
            case EntityType.Element:
                return searchResult.entityProperties.find((e) => e.id === 'path').value;
            case EntityType.DeviceTemplate: {
                const solutionProperty = searchResult.entityProperties.find((e) => e.id === 'solutionName');
                return solutionProperty
                    ? solutionProperty.value
                    : 'APP.SEARCH.USED_IN_ZONES_DEVICE_TEMPLATE_GROUPS';
            }
            case EntityType.Zone:
                return searchResult.entityProperties.find((e) => e.id === 'path').value;
            default:
                return '';
        }
    }
}
