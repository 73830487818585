import { Pipe, PipeTransform } from '@angular/core';

import { SearchResult } from '../search-result.model';

@Pipe({
    name: 'searchProperties',
})
export class SearchPropertiesPipe implements PipeTransform {
    public transform(searchResult: SearchResult): string {
        let title = '';
        const searchProperties = searchResult.entityProperties.filter((e) => e.searchProperty);
        if (searchProperties) {
            searchProperties.forEach((p) => {
                if (p.id !== 'solutionName') {
                    const options: string[] = p.value;
                    Array.isArray(options)
                        ? options.forEach((option) => (title = !title ? option : `${title} • ${option}`))
                        : (title = p.value);
                }
            });
        }
        return title;
    }
}
