<ul class="tab-list tab-list--theme-on-dark-blue">
    <li class="tab-list__item" id="search-tab-all" (click)="selectTab.emit(SearchTab.All)">
        <button class="tab-list__link" [class.is-active]="activeTab === SearchTab.All">
            {{ 'APP.SEARCH.TAB_ALL' | translate }}
        </button>
    </li>
    <li
        class="tab-list__item"
        id="search-tab-devicetemplates"
        (click)="selectTab.emit(SearchTab.DeviceTemplates)"
    >
        <button class="tab-list__link" [class.is-active]="activeTab === SearchTab.DeviceTemplates">
            {{ 'APP.SEARCH.TAB_DEVICE_TEMPLATES' | translate }}
        </button>
    </li>
    <li
        class="tab-list__item"
        id="search-tab-deviceTemplateGroups"
        (click)="selectTab.emit(SearchTab.DeviceTemplateGroups)"
    >
        <button class="tab-list__link" [class.is-active]="activeTab === SearchTab.DeviceTemplateGroups">
            {{ 'APP.SEARCH.TAB_DEVICE_TEMPLATE_GROUPS' | translate }}
        </button>
    </li>
    <li class="tab-list__item" id="search-tab-building" (click)="selectTab.emit(SearchTab.Building)">
        <button class="tab-list__link" [class.is-active]="activeTab === SearchTab.Building">
            {{ 'APP.SEARCH.TAB_BUILDING' | translate }}
        </button>
    </li>
</ul>
