<priva-dialog
    classes="search-dialog"
    type="dynamic"
    alignment="top"
    [title]="'GLOBAL.SEARCH.TITLE' | translate"
    theme="ondarkblue"
    [hasContent]="!!searchTerms || notImplementedYet"
    [buttons]="[]"
    (close)="close()"
>
    <div data-dialog-header>
        <div class="form-input-group form-input-group--theme-on-dark-blue margin-top-2">
            <span class="form-input-group__addon form-input-group__addon--prepend">
                <label
                    class="form-input-group__addon-item form-input-group__addon-item--icon far fa-search"
                    for="search-control"
                ></label>
            </span>
            <input
                class="form-control form-control--theme-on-dark-blue"
                #searchField
                type="search"
                id="search-control"
            />
        </div>

        @if (!!searchTerms) {
            <div class="content-header-end">
                <app-search-dialog-tabs [activeTab]="activeTab" (selectTab)="selectTab($event)" />
            </div>
        }
    </div>

    <div class="search-dialog__content">
        @if (notImplementedYet) {
            <priva-header
                class="margin-top-2"
                [title]="'APP.ERROR_MESSAGE.NOT_IMPLEMENTED_YET' | translate"
            />
        } @else {
            <ng-container *ngrxLet="{ solution: solution$, resultGroups: expandableGroups$ } as vm">
                <priva-header
                    class="margin-top-2"
                    [title]="
                        'APP.SEARCH.RESULT_COUNT' | translate: (activeTab | searchCountParam: vm.resultGroups)
                    "
                />
                <ul class="list list-dense list-borderless margin-bottom-2">
                    @for (resultGroup of vm.resultGroups; track resultGroup) {
                        <li class="list-item-wrapper" [ngClass]="{ 'is-expanded': resultGroup.isExpanded }">
                            @if (
                                activeTab.toString() === resultGroup.searchResultGroupId ||
                                activeTab === SearchTab.All
                            ) {
                                @if (activeTab === SearchTab.All) {
                                    <button
                                        class="list-item list-action"
                                        (click)="toggleExpand(resultGroup)"
                                        tabindex="-1"
                                    >
                                        <div class="list-item-area-main">
                                            <priva-header
                                                [title]="
                                                    resultGroup.searchResultGroupId
                                                        | searchGroupKey
                                                        | translate: { count: resultGroup.results.length }
                                                "
                                            />
                                        </div>
                                    </button>
                                }
                                <div class="expander" [class.is-expanded]="resultGroup.isExpanded">
                                    <div class="expander-content">
                                        <div class="list-wrapper">
                                            <ul
                                                class="list list-dense list-borderless"
                                                id="list-search-results"
                                            >
                                                @for (result of resultGroup.results; track result) {
                                                    <li
                                                        class="list-item-wrapper"
                                                        tabindex="0"
                                                        (keydown.enter)="navigateTo(result)"
                                                    >
                                                        <a
                                                            class="list-item list-action"
                                                            (click)="navigateTo(result)"
                                                        >
                                                            <div class="list-item-area-start">
                                                                @if (result.entityType !== entityTypes.Zone) {
                                                                    <div
                                                                        class="list-icon"
                                                                        [ngClass]="
                                                                            result.entityType | entityIcon
                                                                        "
                                                                    ></div>
                                                                }
                                                                @if (result.entityType === entityTypes.Zone) {
                                                                    <next-zone-icons
                                                                        [type]="result | zoneType"
                                                                        color="black"
                                                                    />
                                                                }
                                                            </div>
                                                            <div class="list-item-area-main">
                                                                <div
                                                                    class="list-item-title"
                                                                    priva-mark-match
                                                                    [text]="result.entity.name"
                                                                    [filter]="searchTerms"
                                                                ></div>
                                                                <div
                                                                    class="search-properties"
                                                                    priva-mark-match
                                                                    [text]="result | searchProperties"
                                                                    [filter]="searchTerms"
                                                                ></div>
                                                                <div class="search-specification">
                                                                    {{
                                                                        result
                                                                            | searchSpecification
                                                                            | translate
                                                                                : (result
                                                                                      | searchSpecificationParams)
                                                                    }}
                                                                </div>
                                                            </div>
                                                            <div class="list-item-area-end">
                                                                <span
                                                                    class="list-item-action-indicator far fa-chevron-right"
                                                                ></span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                        </li>
                    }
                </ul>
            </ng-container>
        }
    </div>
</priva-dialog>
