import { Pipe, PipeTransform } from '@angular/core';

import { SearchGroupType, SearchTab } from '../search-group.enum';
import { SearchResultGroup } from '../search-result.model';

@Pipe({
    name: 'searchCountParam',
})
export class SearchCountParamPipe implements PipeTransform {
    public transform(searchTab: SearchTab, resultGroups: SearchResultGroup[]): any {
        let count = 0;
        if (searchTab === SearchTab.All) {
            resultGroups.forEach((s) => (count = count + s.results.length));
        } else {
            resultGroups.forEach((s) => {
                if (SearchGroupType[s.searchResultGroupId] === SearchTab[searchTab]) {
                    count = count + s.results.length;
                }
            });
        }
        return { count };
    }
}
