import { Pipe, PipeTransform } from '@angular/core';

import { EntityType } from 'app/shared/entity';

import { SearchResult } from '../search-result.model';

@Pipe({
    name: 'searchSpecificationParams',
})
export class SearchSpecificationParamsPipe implements PipeTransform {
    public transform(searchResult: SearchResult): any {
        switch (searchResult.entityType) {
            case EntityType.DeviceTemplate: {
                const countProperty = searchResult.entityProperties.find((e) => e.id === 'zoneCount');
                return countProperty
                    ? {
                          zoneCount: searchResult.entityProperties.find((e) => e.id === 'zoneCount').value,
                          deviceTemplateGroupCount: searchResult.entityProperties.find(
                              (e) => e.id === 'deviceTemplateGroupCount',
                          ).value,
                      }
                    : undefined;
            }
            case EntityType.DeviceTemplateGroup:
            case EntityType.Element:
            case EntityType.Zone:
            default:
                return undefined;
        }
    }
}
